<template>
<div>
    <patent-search-database-list></patent-search-database-list>
</div>
</template>

  
<script>
export default {}
</script>
